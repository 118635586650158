import Cookies from 'js-cookie'
import { clearRTDTimer } from './rtdTimer';
const TokenKey = 'x-access-token'
var inFifteenMinutes = new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000)
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { expires: inFifteenMinutes })
}

export function removeToken() {
  clearRTDTimer();
  return Cookies.remove(TokenKey)
}
