import request from '../config/axios';
import dayjs from 'dayjs';

export default class SiteAnalyzeService {
    getFactorCountByTimeRange = (params) => request({
        url: '/siteAnalyze/getFactorCountByTimeRange', method: 'get', params: {
            ...params,
        },
    });

    getCurrentMonthCount(paramsObj) {
        let now = dayjs();
        paramsObj.startTime = now.startOf('month').valueOf();
        paramsObj.endTime = now.valueOf();
        return request({
            url: '/siteAnalyze/getFactorCountByTimeRange', method: 'get', params: {
                ...paramsObj,
            },
        });
    }

    getCurrentQuarterCount(paramsObj) {
        var quarterOfYear = require('dayjs/plugin/quarterOfYear');
        dayjs.extend(quarterOfYear);
        paramsObj.startTime = dayjs().startOf('quarter').valueOf();
        paramsObj.endTime = dayjs().valueOf();
        return request({
            url: '/siteAnalyze/getFactorCountByTimeRange', method: 'get', params: {
                ...paramsObj,
            },
        });
    }

    getCurrentYearCount(paramsObj) {
        paramsObj.startTime = dayjs().startOf('year').valueOf();
        paramsObj.endTime = dayjs().valueOf();
        return request({
            url: '/siteAnalyze/getFactorCountByTimeRange', method: 'get', params: {
                ...paramsObj,
            },
        });
    }

    getLastMonthCount(paramsObj) {
        let dayOfPreviousMonth = dayjs().add(-1, 'month');
        let firstDayOfPreviousMonth = dayOfPreviousMonth.startOf('month');
        paramsObj.startTime = firstDayOfPreviousMonth.valueOf();
        paramsObj.endTime = dayOfPreviousMonth.valueOf();

        return request({
            url: '/siteAnalyze/getFactorCountByTimeRange', method: 'get', params: {
                ...paramsObj,
            },
        });
    }

    getLastQuarterCount(paramsObj) {
        let dayOfPreviousQuarter = dayjs().add(-1, 'quarter');
        let firstDayOfPreviousQuarter = dayOfPreviousQuarter.startOf('quarter');
        paramsObj.startTime = firstDayOfPreviousQuarter.valueOf();
        paramsObj.endTime = dayOfPreviousQuarter.valueOf();

        return request({
            url: '/siteAnalyze/getFactorCountByTimeRange', method: 'get', params: {
                ...paramsObj,
            },
        });
    }

    getLastYearCount(paramsObj) {
        let dayOfPreviousYear = dayjs().add(-1, 'year');
        let firstDayOfPreviousYear = dayOfPreviousYear.startOf('year');
        paramsObj.startTime = firstDayOfPreviousYear.valueOf();
        paramsObj.endTime = dayOfPreviousYear.valueOf();
        return request({
            url: '/siteAnalyze/getFactorCountByTimeRange', method: 'get', params: {
                ...paramsObj,
            },
        });
    }

    getDataUploadRateOfEveryDayInMonth(paramsObj) {
        return request({
            url: '/siteAnalyze/getDataUploadRateOfEveryDayInMonth', method: 'get', params: {
                ...paramsObj,
            },
        });
    }

    getDataUploadRateOfDateRange(paramsObj) {
        return request({
            url: '/siteAnalyze/getDataUploadRateOfDateRange', method: 'get', params: {
                ...paramsObj,
            },
        });
    }

    siteAllRecord(paramsObj) {
        return request({
            url: '/recordOfMaintain/siteAllRecord', method: 'get', params: {
                ...paramsObj,
            },
        });
    }

    downloadRecordPdf(paramsObj) {
        return request({
            url: '/recordOfMaintain/downloadRecordPdf', method: 'get', params: {
                ...paramsObj,
            },
            responseType: 'blob',
        });
    }
}