<template>
	<div class="layout-footer">
        <div class="footer-logo-container">
<!--            <img id="footer-logo" :src="'layout/images/logo-'+ (colorScheme === 'light' ? 'dark' : 'light') + '.png'" alt="atlantis-layout"/>-->
            <img id="footer-logo" style='height: 3rem;width: 3rem' :src="'layout/images/logo/logo.png'" alt="atlantis-layout"/>
            <span class="app-name">QingHeCloud</span>
        </div>
        <span class="copyright">&#169; 深圳市青禾云科技有限公司 - 2022</span>
    </div>
</template>

<script>
export default {
	props: {
		colorScheme: String
	}
}
</script>

