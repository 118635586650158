import request from '../config/axios';

export default class LoginService {
    login = (username, password, type, token) => request({
        url: '/webToken', method: 'post', params: {
            username,
            password,
            type,
            ...token,
        },
    });
}
