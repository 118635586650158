let baseUrl = '';
let uploadUrl = '';
let mqttUrl = '';

if (process.env.NODE_ENV === 'development') {
    // baseUrl = `http://127.0.0.1:8090`;
    baseUrl = `http://nas.qinghecloud.com:9528`;
    uploadUrl = `http://127.0.0.1:8090/upload`;
    mqttUrl = `ws://192.168.6.6:8083/mqtt`;

} else if (process.env.NODE_ENV === 'production') {
    baseUrl = `https://epapi.qinghecloud.com`;
    uploadUrl = 'https://epapi.qinghecloud.com/upload';
    mqttUrl = `ws://106.53.214.113:8083/mqtt`;

}

export {
    baseUrl,
    uploadUrl,
    mqttUrl,
};
