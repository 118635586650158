import { createStore } from 'vuex';
import user from './modules/user';
import getters from './getters';
import rtd from './modules/rtd';

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        user,
        rtd
    },
    getters
});
