import axios from 'axios';
import { encode } from 'js-base64';
import { baseUrl } from './env';
import website from './website';
import { serialize } from '../utils/utils';
import { getToken } from '../utils/auth';
import router from '../router';
import store from '../store';

axios.defaults.timeout = 10000;
axios.defaults.baseURL = baseUrl;

axios.defaults.validateStatus = function(status) {
    return status >= 200 && status <= 500;
};

// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true;

// HTTP Request 拦截
axios.interceptors.request.use(config => {
    const meta = (config.meta || {});
    const isToken = meta.isToken === false;
    config.headers['Authorization'] = `Basic ${encode(`${website.clientId}:${website.clientSecret}`)}`;
    if (getToken() && !isToken) {
        config.headers['qhc-Auth'] = 'bearer ' + getToken(); // 让每个请求携带token--['Authorization']为自定义key 请根据实际情况自行修改
    }
    // headers中配置serialize为true开启序列化
    if (config.method === 'post' && meta.isSerialize === true) {
        config.data = serialize(config.data);
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// HTTP Response拦截
axios.interceptors.response.use(res => {
    const status = res.data.code || 200;
    const statusWhiteList = website.statusWhiteList || [];
    const message = res.data.msg || '未知错误';
    // 如果在白名单里则自行catch逻辑处理
    if (statusWhiteList.includes(status)) return res;
    // 如果是401则跳转到登录页面
    if (status === 401) {
        store.dispatch('FedLogOut').then(() => router.push({ path: '/login' }));
    } else if (status === 400) {
        // 账号密码错误
    } else if (status !== 200) {
        // 如果请求为非200否者默认统一处理
        return Promise.reject(new Error(message));
    }
    return res;
}, error => {

    if (error.response.status === 503) {
        console.log(this);
    }

    return Promise.reject(new Error(error));
});

export default axios;