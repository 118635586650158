import { getStore, setStore } from '../../utils/store';
import SiteFactorDataService from '../../service/SiteFactorDataService';
import dayjs from 'dayjs';
import SiteAnalyzeService from '../../service/SiteAnalyzeService';
import store from '../index';

const rtd = {
    state: {
        rtdList: getStore({ name: 'rtdList' }) || [],
        rtdUpdateDateTime: getStore({ name: 'rtdUpdateDateTime' }) || '',
        rtdListChart: getStore({ name: 'rtdListChart' }) || {
            xAxis: {
                type: 'category',
                data: [],
            },
            yAxis: {
                type: 'value',
            },
            backgroundColor: 'transparent',
            grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
            series: [
                {
                    data: [],
                    type: 'line',
                    smooth: true,
                },
            ],
        },
        alarmList: getStore({ name: 'alarmList' }) || [],
        last30DaysDataUpPct: getStore({ name: 'dataUpPct' }) || [],
        countAnalyze: getStore({ name: 'countAnalyze' }) || {
            currentMonthCount: 0,
            monthChangeRate: 0,
            currentQuarterCount: 0,
            quarterChangeRate: 0,
            currentYearCount: 0,
            yearChangeRate: 0,
        },
    },
    actions: {
        UpdateRTD({ commit }) {
            return new Promise((resolve) => {
                new SiteFactorDataService().newestRealtimeData().then(res => {
                    if (res.data.success) {
                        let rtdList = res.data.data;
                        rtdList.sort((a, b) => a.polId - b.polId);
                        let alarmList = getStore({ name: 'alarmList' }) || [];
                        rtdList.forEach(rtd => {
                            if (rtd.minValue === 0 && rtd.maxValue === 0) {
                                rtd.state = '正常';
                                if (rtd.polId !== null && rtd.polId !== '') {
                                    let index = alarmList.indexOf(rtd.polId);
                                    if (index >= 0) {
                                        alarmList.splice(index);
                                        commit('SET_ALARM_LIST', alarmList);
                                    }
                                }
                            } else if (rtd.value >= rtd.minValue && rtd.value <= rtd.maxValue) {
                                rtd.state = '正常';
                                if (rtd.polId !== null && rtd.polId !== '') {
                                    let index = alarmList.indexOf(rtd.polId);
                                    if (index >= 0) {
                                        alarmList.splice(index);
                                        commit('SET_ALARM_LIST', alarmList);
                                    }
                                }
                            } else {
                                rtd.state = '超标';
                                if (rtd.polId !== null && rtd.polId !== '') {
                                    let index = alarmList.indexOf(rtd.polId);
                                    if (index < 0) {
                                        alarmList.push(rtd.polId);
                                        commit('SET_ALARM_LIST', alarmList);
                                    }
                                }
                            }
                            rtd.range = '';
                            if (rtd.minValue > 0 && rtd.maxValue > 0) {
                                rtd.range = rtd.minValue + ' 至 ' + rtd.maxValue;
                            } else {
                                if (rtd.maxValue > 0) {
                                    rtd.range = '< ' + rtd.maxValue;
                                }
                            }
                            if (rtd.unit === '') {
                                rtd.unit = '/';
                            }
                            const rtdUpdateDateTime = dayjs(rtd.timestamp * 1000).format('YYYY年MM月DD日 HH:mm:ss');
                            commit('SET_RTD_UPDATE_TIME', rtdUpdateDateTime);
                        });
                        if (rtdList.length > 0) {
                            commit('SET_RTD_LIST', rtdList);
                        } else {
                            console.log('rtdList is empty!');
                        }
                    }
                    resolve(res);
                });
            });
        },
        UpdateRTDChart({ commit }) {
            return new Promise((resolve) => {
                const startTs = dayjs().startOf('day').valueOf();
                const chartOption = {
                    xAxis: {
                        type: 'category',
                        data: [],
                    },
                    yAxis: {
                        type: 'value',
                    },
                    tooltip: {
                        trigger: 'axis',
                    },
                    legend: {
                        data: [],
                    },
                    backgroundColor: 'transparent',
                    grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
                    series: [],
                };
                new SiteFactorDataService().realtimeChartData(2, startTs, dayjs().valueOf()).then(res => {
                    if (res.data.success) {
                        chartOption.xAxis.data = res.data.data.tsList.map((ts) => dayjs(ts).format('HH:mm'));
                        res.data.data.pollutionDataList.sort((a, b) => a.polId - b.polId);
                        for (let i = 0; i < res.data.data.pollutionDataList.length; i++) {
                            const item = res.data.data.pollutionDataList[i];
                            chartOption.legend.data.push(item.polName);
                            chartOption.series[i] = {
                                name: item.polName,
                                data: item.values,
                                type: 'line',
                                smooth: true,
                            };
                        }

                        commit('SET_RTD_LIST_CHART', chartOption);
                    }
                    resolve(res);
                });
            });
        },
        UpdateDataUpPct({ commit }) {
            return new Promise((resolve) => {
                let end = dayjs().endOf('day');
                let start = end.add(-30, 'day');
                new SiteAnalyzeService().getDataUploadRateOfDateRange({
                    'siteId': store.getters.currentSelectedSite.id,
                    'startTime': start.valueOf(),
                    'endTime': end.valueOf(),
                }).then(res => {
                    const dataUploadAnalyze = [];
                    if (res.data.success) {
                        for (let i = 0; i < res.data.data.length; i++) {
                            const d = res.data.data[i];
                            dataUploadAnalyze.push({
                                'label': d.month + '/' + d.day,
                                'value': parseInt(d.pctHour.toFixed(0)),
                                'color': d.pctHour >= 90 ? 'success' : 'error',
                            });
                        }

                        commit('SET_DATA_UP_PCT', dataUploadAnalyze);
                    }
                    resolve(res);
                });
            });
        },
        UpdateCountAnalyze({ commit }) {
            const siteTypeId = store.getters.currentSelectedSite.siteTypeId;
            let polId = '1';
            this.siteType = '废水';
            if (siteTypeId === 'ea64fbb56d13a5fe0fa181693eefd227') {
                this.siteType = '废气';
                polId = '78';
            }
            const paramsObj = {
                'siteId': store.getters.currentSelectedSite.id,
                'polId': polId,
                'type': 4,
            };

            const countAnalyze = {};
            let siteAnalyzeService = new SiteAnalyzeService();
            return Promise.all([
                siteAnalyzeService.getCurrentMonthCount(paramsObj),
                siteAnalyzeService.getCurrentQuarterCount(paramsObj),
                siteAnalyzeService.getCurrentYearCount(paramsObj),
                siteAnalyzeService.getLastMonthCount(paramsObj),
                siteAnalyzeService.getLastQuarterCount(paramsObj),
                siteAnalyzeService.getLastYearCount(paramsObj),
            ]).then((res) => {
                countAnalyze.currentMonthCount = res[0].data.data.count;
                countAnalyze.currentQuarterCount = res[1].data.data.count;
                countAnalyze.currentYearCount = res[2].data.data.count;
                const tempLastMonthCount = res[3].data.data.count;
                const tempLastQuarterCount = res[4].data.data.count;
                const tempLastYearCount = res[5].data.data.count;
                countAnalyze.monthChangeRate = (countAnalyze.currentMonthCount - tempLastMonthCount) / tempLastMonthCount;
                countAnalyze.quarterChangeRate = (countAnalyze.currentQuarterCount - tempLastQuarterCount) / tempLastQuarterCount;
                countAnalyze.yearChangeRate = (countAnalyze.currentYearCount - tempLastYearCount) / tempLastYearCount;
                if (countAnalyze.monthChangeRate > 1000000000) {
                    countAnalyze.monthChangeRate = 100;
                }
                if (countAnalyze.quarterChangeRate > 1000000000) {
                    countAnalyze.quarterChangeRate = 100;
                }
                if (countAnalyze.yearChangeRate > 1000000000) {
                    countAnalyze.yearChangeRate = 100;
                }

                commit('SET_COUNT_ANALYZE', countAnalyze);
            });
        },
    },
    mutations: {
        SET_RTD_UPDATE_TIME: (state, rtdUpdateDateTime) => {
            state.rtdUpdateDateTime = rtdUpdateDateTime;
            setStore({ name: 'rtdUpdateDateTime', content: state.rtdUpdateDateTime });
        },
        SET_RTD_LIST: (state, rtdList) => {
            state.rtdList = rtdList;
            setStore({ name: 'rtdList', content: state.rtdList });
        },
        SET_RTD_LIST_CHART: (state, option) => {
            state.rtdListChart = option;
            setStore({ name: 'rtdListChart', content: state.rtdListChart });
        },
        SET_ALARM_LIST: (state, alarmList) => {
            state.alarmList = alarmList;
            setStore({ name: 'alarmList', content: state.alarmList });
        },
        SET_DATA_UP_PCT: (state, last30DaysDataUpPct) => {
            state.last30DaysDataUpPct = last30DaysDataUpPct;
            setStore({ name: 'dataUpPct', content: state.last30DaysDataUpPct });
        },
        SET_COUNT_ANALYZE: (state, countAnalyze) => {
            state.countAnalyze = countAnalyze;
            setStore({ name: 'countAnalyze', content: state.countAnalyze });
        },

    },
};
export default rtd;
