<template>
    <div class='login-body'>
        <Toast position='center' />

        <div class='login-image'>
            <img :src="'layout/images/pages/login-'+ loginColor + '.png'" alt='atlantis'>
        </div>
        <div class='login-panel p-fluid'>
            <div class='flex flex-column'>
                <div class='flex align-items-center mb-6 logo-container'>
                    <img :src="'layout/images/logo/logo.png'" style='height: 5rem;width: 5rem' class='login-logo' />
                    <img :src="'layout/images/appname-'+ color + '.png'" class='login-appname' />
                </div>
                <div class='form-container'>
                    <span class='p-input-icon-left'>
                        <i class='pi pi-user'></i>
                        <InputText v-model='username' type='text' placeholder='用户名' />
                    </span>
                    <span class='p-input-icon-left'>
                        <i class='pi pi-key'></i>
                        <InputText v-model='password' type='password' placeholder='密码' />
                    </span>
                </div>
                <div class='button-container'>
                    <Button type='button' label='登录' @click='handleLogin'></Button>
                    <span>使用时遇到问题?<a href='mailto:qinghecloud@163.com'>点此联系系统管理员</a></span>
                </div>
            </div>
            <Toast position='center' :closable='false' />
            <div class='login-footer flex align-items-center'>
                <div class='flex align-items-center login-footer-logo-container'>
                    <img src='layout/images/logo/logo.png' style='width: 2rem;height: 2rem' class='login-footer-logo' />
                    <img src='layout/images/appname-gray.png' class='login-footer-appname' />
                </div>
                <span>Copyright 2022</span>
            </div>
        </div>
        <Dialog header='深圳市青禾云科技有限公司' v-model:visible='display' style='width: 45%' :modal='true'>
            <p>
                <span>检测到该账号未激活或者秘钥已经过期，请输入秘钥，激活秘钥后继续使用。</span>
                <span>若无秘钥，请联系我们的工作人员（施伟 15039722296），购买秘钥。谢谢！</span>
            </p>
            <div>
                <h3>激活秘钥</h3>
                <div class="p-inputgroup col-6">
                    <InputText placeholder="请输入32位秘钥"/>
                    <Button icon="pi pi-check" class="p-button-plain" label='激活'/>
                </div>
            </div>
            <template #footer></template>
        </Dialog>
    </div>
</template>

<script>
import LoginService from '../service/LoginService';
import MyInfoService from '../service/MyInfoService';
import dayjs from 'dayjs';

export default {
    data() {
        return {
            display: false,
            license: '',
            username: '',
            password: '',
            token: '',
            type: 'account',
            loginService: null,
        };
    },
    created() {
        this.loginService = new LoginService();
    },

    computed: {
        loginColor() {
            if (this.$appState.colorScheme === 'light') return 'ondark';
            return 'onlight';
        },
        color() {
            if (this.$appState.colorScheme === 'light') return 'dark';
            return 'light';
        },
    },

    methods: {
        handleLogin() {
            this.$store.dispatch('Login', {
                'username': this.username,
                'password': this.password,
                'type': this.type,
                'token': {
                    'sig': '-',
                    'token': '-',
                    'sessionId': '-',
                },
            }).then(res => {
                if (res.data.success) {
                    let licenseExpireDate = res.data.data.licenseExpireDate;
                    if (licenseExpireDate) {
                        const expireDate = dayjs(licenseExpireDate, 'YYYY-MM-DD HH:mm:ss');
                        if (expireDate.isBefore(dayjs())) {
                            this.display = true;
                            return;
                        }
                    }

                    this.$toast.add({ severity: 'success', summary: '登录成功', detail: '欢迎使用，即将跳转到首页。', life: 3000 });
                    setTimeout(() => {
                        this.$router.replace('/');
                    }, 3000);

                    if (!this.$store.getters.currentSelectedSite) {
                        // 本地无站点信息缓存
                        new MyInfoService().enterpriseList().then(res => {
                            if (res.data.success) {
                                this.enterpriseList = res.data.data;

                                if (this.enterpriseList.length > 0 && this.enterpriseList[0].siteList.length > 0) {
                                    let defaultEnterprise = this.enterpriseList[0];
                                    this.$store.dispatch('changeSelectedEnterprise', defaultEnterprise);
                                    let defaultSite = defaultEnterprise.siteList[0];
                                    this.currentSelectedSiteId = defaultSite.id;
                                    this.$store.dispatch('changeSelectedSite', defaultSite);
                                }
                            } else {
                                console.log('登录成功，但获取关联企业信息失败！');
                            }
                        });

                    }
                } else {
                    this.$toast.add({ severity: 'error', summary: '登录失败', detail: '请检查账号或密码是否输入错误。', life: 3000 });
                }
            });
        },
    },
};
</script>