/**
 * 全站权限配置
 *
 */
import router from '../router';
import store from '../store';
import { getToken } from '../utils/auth';

router.beforeEach((to, from, next) => {
    const meta = to.meta || {};
    if (getToken()) {
        // 如果用户信息为空则获取用户信息，获取用户信息失败，跳转到登录页
        if (store.getters.token.length === 0) {
            store.dispatch('FedLogOut').then(() => {
                next({ path: '/login' });
            });
        } else {
            next();
        }
    } else {
        // 判断是否需要认证，没有登录访问去登录页
        if (meta.isAuth === false) {
            next();
        } else {
            next('/login');
        }
    }
});

router.afterEach(() => {
    // let title = store.getters.tag.label;
    // const i18n = store.getters.tag.meta.i18n;
    // title = router.$avueRouter.generateTitle(title, i18n);
    // // 根据当前的标签也获取label的值动态设置浏览器标题
    // router.$avueRouter.setTitle(title);
});
