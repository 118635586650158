<template>
    <div id='layout-config'>
        <a id='layout-config-button' class='layout-config-button' @click='onConfigButtonClick($event)'>
            <i class='pi pi-phone'></i>
        </a>
        <div class='layout-config' :class="{ 'layout-config-active': configActive }" @click='onConfigClick'>
            <div class='layout-config' :class="{ 'layout-config-active': configActive }" @click='onConfigClick'>
                <h2>联系我们</h2>
                <div class='card mt-5'>
                    <h4>运维公司</h4>
                    <div class='user-detail-wrapper'>
                        <div class='user-detail-content'>
                            <img src='layout/images/dashboard/tyhb-logo.jpg' alt='atlantis'
                                 class='user-image border-circle'>
                            <span class='user-name'>深圳市天益环保科技有限公司</span>
                            <span class='user-number'>环保在线监测设备销售及运营服务，环境工程治理，环保产品研发与销售，环保技术咨询服务。</span>
                        </div>
                    </div>
                    <div class='daily-plan-wrapper'>
                        <span class='today-date'>联系电话</span>
                        <ul>
                            <li>
                                <span class='event-time'>企业电话</span>
                                <br>
                                <span class='event-topic'>4008 989 383</span>
                            </li>
<!--                            <li>-->
<!--                                <span class='event-time'>刘弘扬</span>-->
<!--                                <br>-->
<!--                                <span class='event-topic'>180 0000 8888</span>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <span class='event-time'>朱涛</span>-->
<!--                                <br>-->
<!--                                <span class='event-topic'>180 0000 8888</span>-->
<!--                            </li>-->
                        </ul>
                    </div>
                </div>
                <Divider />
                <div class='card'>
                    <h4>软件服务</h4>
                    <div class='user-detail-wrapper'>
                        <div class='user-detail-content'>
                            <img src='layout/images/dashboard/qhc-logo.png' alt='atlantis'
                                 class='user-image border-circle'>
                            <span class='user-name'>深圳市青禾云科技有限公司</span>
                            <span class='user-number'>专注于企业级软件研发，承接各行业专业软件、物联网系统定制开发。</span>
                        </div>
                    </div>
                    <div class='daily-plan-wrapper'>
                        <span class='today-date'>联系电话</span>
                        <ul>
                            <li>
                                <span class='event-time'>施先生</span>
                                <br>
                                <span class='event-topic'>150 3972 2296</span>
                            </li>
                            <li>
                                <span class='event-time'>徐先生</span>
                                <br>
                                <span class='event-topic'>132 5383 1695</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactUS',
    emits: ['config-button-click', 'config-click'],
    props: {
        configActive: {
            type: Boolean,
            default: null,
        },
        configClick: {
            type: Boolean,
            default: null,
        },
    },
    data() {
        return {};
    },
    methods: {
        onConfigButtonClick(event) {
            this.$emit('config-button-click', event);
            event.preventDefault();
        },
        onConfigClick(event) {
            this.$emit('config-click', event);
        },

    },
};
</script>
