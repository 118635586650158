<template>
    <div class='layout-topbar'>
        <div class='layout-topbar-left'>
            <a href='#' class='topbar-menu-button' @click='onMenuButtonClick($event)'>
                <i class='pi pi-bars'></i>
            </a>

            <a href='#' class='logo'>
                <img style='height: 4.3rem;width: 4.3rem' :src="'layout/images/logo/logo.png'">

            </a>
            <a href='#'>
                <img :src="'layout/images/appname-'+ (colorScheme === 'light' ? 'dark' : 'light') + '.png'"
                     class='app-name' />
            </a>
        </div>

        <AppMenu :model='items' :menuMode='menuMode' :colorScheme='colorScheme' :menuActive='menuActive'
                 :activeInlineProfile='activeInlineProfile'
                 @sidebar-mouse-over='onSidebarMouseOver' @sidebar-mouse-leave='onSidebarMouseLeave'
                 @toggle-menu='onToggleMenu' @change-inlinemenu='onChangeActiveInlineMenu'
                 @menu-click='onMenuClick' @root-menuitem-click='onRootMenuItemClick'
                 @menuitem-click='onMenuItemClick' />
        <ConfirmPopup></ConfirmPopup>
        <div class='layout-topbar-right'>
            <ul class='layout-topbar-right-items'>
                <li class='mr-2'>
                    <a href='#' @click='onTopbarSignOutItemClick($event)'>
                        <i class='topbar-icon pi pi-fw pi-sign-out'></i>
                        <span class='topbar-item-name'>退出系统</span>
                    </a>
                </li>
                <li class='mr-2'>
                    <a v-tooltip.left="$store.getters.alarmList.length === 0?'当前无警报':'有警报'">
                        <i class='topbar-icon pi pi-fw pi-bell'
                           :class="$store.getters.alarmList.length > 0?'p-error':''"></i>
                        <span class='topbar-item-name'
                        >警报铃</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import AppMenu from './AppMenu.vue';

export default {
    emits: ['menu-click', 'menuitem-click', 'root-menuitem-click', 'menubutton-click', 'sidebar-mouse-over', 'sidebar-mouse-leave', 'toggle-menu', 'change-inlinemenu', 'topbar-item-click'],
    data() {
        return {
            activeTopbarItem: null,
            userRealName: null,
            firstName: '',
        };
    },
    props: {
        items: Array,
        menuMode: String,
        colorScheme: String,
        topbarMenuActive: {
            type: Boolean,
            default: false,
        },
        menuActive: {
            type: Boolean,
            default: false,
        },
        activeInlineProfile: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (this.$store.getters.userInfo) {
            this.userRealName = this.$store.getters.userInfo.userInfo.realName;
            if (this.userRealName) {
                this.firstName = this.userRealName.substring(0, 1);
            }
        }
    },
    methods: {
        onMenuClick(event) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },
        onMenuButtonClick(event) {
            this.$emit('menubutton-click', event);
        },
        onTopbarItemClick(event, item) {
            this.$emit('topbar-item-click', event, item);
            event.preventDefault();
        },
        onTopbarSignOutItemClick(event) {
            event.preventDefault();
            this.$confirm.require({
                target: event.currentTarget,
                message: '确定要退出系统吗？',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: '是',
                rejectLabel: '否',
                accept: () => {
                    this.$store.dispatch('LogOut').then(() => {
                        this.$router.push({ path: '/login' });
                    });
                },
                reject: () => {
                    //callback to execute when user rejects the action
                },
            });

        },
        onSidebarMouseOver() {
            this.$emit('sidebar-mouse-over');
        },
        onSidebarMouseLeave() {
            this.$emit('sidebar-mouse-leave');
        },
        onToggleMenu(event) {
            this.$emit('toggle-menu', event);
        },
        onChangeActiveInlineMenu() {
            this.$emit('change-inlinemenu');
        },
        isOverlay() {
            return this.menuMode === 'overlay';
        },
    },
    components: {
        AppMenu,
    },
};
</script>
