import { getStore, setStore } from '../../utils/store';
import LoginService from '../../service/LoginService';
import { removeToken, setToken } from '../../utils/auth';

const user = {
    state: {
        userInfo: getStore({ name: 'userInfo' }) || {},
        permission: getStore({ name: 'permission' }) || {},
        menu: getStore({ name: 'menu' }) || [],
        token: getStore({ name: 'token' }) || '',
        alarmNotice: getStore({ name: 'alarmNotice' } || 'disable'),
        selectedEnterprise: getStore({ name: 'selectedEnterprise' } || null),
        selectedSite: getStore({ name: 'selectedSite' } || null),
        routerRoot: getStore({ name: 'routerRoot' } || ''),
    },
    actions: {
        Login({ commit }, userInfo) {
            return new Promise((resolve) => {
                new LoginService().login(userInfo.username, userInfo.password, userInfo.type, userInfo.token).then(res => {
                    const data = res.data.data;
                    if (data) {
                        commit('SET_TOKEN', data.accessToken);
                        commit('SET_USERIFNO', data);
                    }
                    resolve(res);
                });
            });
        },
        // 登出
        LogOut({ commit }) {
            return new Promise((resolve) => {
                commit('SET_TOKEN', '');
                commit('SET_USERIFNO', {});
                removeToken();
                resolve();
            });
        },
        // 注销session
        FedLogOut({ commit }) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '');
                commit('SET_USERIFNO', {});
                removeToken();
                resolve();
            });
        },
        changeAlarmNotice({ commit }, op) {
            return new Promise(resolve => {
                commit('SET_ALARM_NOTICE', op);
                resolve();
            });
        },
        changeSelectedEnterprise({ commit }, enterprise) {
            return new Promise(resolve => {
                commit('SET_SELECTED_ENTERPRISE', enterprise);
                resolve();
            });
        },
        changeSelectedSite({ commit }, siteObj) {
            return new Promise(resolve => {
                commit('SET_SELECTED_SITE', siteObj);
                commit('SET_ROUTER_ROOT', siteObj.siteName);
                commit('SET_RTD_LIST', []);

                resolve();
            });
        },
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            setToken(token);
            state.token = token;
            setStore({ name: 'token', content: state.token, type: 'session' });
        },
        SET_USERIFNO: (state, userInfo) => {
            state.userInfo = userInfo;
            setStore({ name: 'userInfo', content: state.userInfo });
        },
        SET_ALARM_NOTICE: (state, op) => {
            state.alarmNotice = op;
            setStore({ name: 'alarmNotice', content: state.alarmNotice });
        },
        SET_SELECTED_ENTERPRISE: (state, enterprise) => {
            state.selectedEnterprise = enterprise;
            setStore({ name: 'selectedEnterprise', content: state.selectedEnterprise });
        },
        SET_SELECTED_SITE: (state, siteObj) => {
            state.selectedSite = siteObj;
            setStore({ name: 'selectedSite', content: state.selectedSite });
        },
        SET_ROUTER_ROOT: (state, routerRoot) => {
            state.routerRoot = routerRoot;
            setStore({ name: 'routerRoot', content: state.routerRoot });
        },
    },
};
export default user;
