<template>
    <div class='layout-rightmenu' :class="{'layout-rightmenu-active': rightMenuActive}"
         @click='onRightMenuClick($event)'>
        <a @click='onChangeRightMenuActive' class='layout-rightmenu-close'>
            <i class='pi pi-times'></i>
        </a>
        <h5>秘钥</h5>
        <div v-if='$store.getters.userInfo.userInfo.licenseExpireDate'>
            <p>
                {{$store.getters.userInfo.userInfo.licenseText}}
            </p>
            <span>到期时间: {{$store.getters.userInfo.userInfo.licenseExpireDate}}</span>
        </div>

        <hr />
        <h5>主题</h5>
        <div class='field-radiobutton'>
            <RadioButton name='colorScheme' value='light' v-model='d_colorScheme' id='theme1'
                         @change="changeColorScheme('light')"></RadioButton>
            <label for='theme1'>明亮</label>
        </div>
        <div class='field-radiobutton'>
            <RadioButton name='colorScheme' value='dark' v-model='d_colorScheme' id='theme2'
                         @change="changeColorScheme('dark')"></RadioButton>
            <label for='theme2'>暗夜</label>
        </div>
        <hr />
        <div class='flex justify-content-start'>
            <h5>警报铃声</h5>
            <Button @click='this.$refs.alarmSound.play()' icon='pi pi-volume-up'
                    class='p-button-rounded p-button-success ml-2' style='width: 1.6rem;height:1.6rem;'
                    v-tooltip="'点击测试警报铃声'" />
        </div>
        <audio ref='alarmSound' src='sounds/alarm.mp3'></audio>
        <div class='field-radiobutton'>
            <RadioButton name='alarmNotice' value='enable' v-model='d_alarmNotice' id='alarmNotice1'
                         @change="changeAlarmNotice('enable')"></RadioButton>
            <label for='alarmNotice1'>开启</label>
        </div>
        <div class='field-radiobutton'>
            <RadioButton name='alarmNotice' value='disable' v-model='d_alarmNotice' id='alarmNotice2'
                         @change="changeAlarmNotice('disable')"></RadioButton>
            <label for='alarmNotice2'>静音</label>
        </div>
        <hr />
        <h5>我的排放口</h5>
        <div v-for='enterprise in enterpriseList' :key='enterprise.id'>
            <div class='mb-2'>
                <label>{{ enterprise.enterpriseName }}</label>
            </div>
            <div class='field-radiobutton ml-3' v-for='site in enterprise.siteList' :key='site.id'>
                <RadioButton name='siteId' :value='site.id' v-model='currentSelectedSiteId' :id='site.id'
                             @change='changeSelectedSite(enterprise,site)'></RadioButton>
                <label :for='site.id'>{{ site.siteName }}</label>
            </div>
        </div>

    </div>
</template>

<script>
import MyInfoService from './service/MyInfoService';
import { checkAndGetRTD } from './utils/rtdTimer';

export default {
    props: {
        rightMenuActive: {
            type: Boolean,
            default: false,
        }, configActive: {
            type: Boolean,
            default: null,
        },
        colorScheme: String,
        theme: String,
        alarmNotice: String,
    },
    emits: ['rightmenu-active', 'rightmenu-click', 'change-alarm-notice', 'change-color-scheme'],
    data() {
        return {
            d_colorScheme: this.colorScheme,
            d_alarmNotice: this.alarmNotice,
            currentSelectedSiteId: '',
            enterpriseList: [],
            myInfoService: null,
            timer: null,
        };
    },
    created() {
        this.myInfoService = new MyInfoService();
    },
    beforeUnmount() {
        if (this.timer != null) {
            clearInterval(this.timer);
            this.timer = null;
        }
    },
    mounted() {
        if (this.timer != null) {
            clearInterval(this.timer);
            this.timer = null;
        }
        this.timer = setInterval(() => {
            if (this.$store.getters.alarmNotice === 'enable' && this.$store.getters.alarmList.length > 0) {
                this.$refs.alarmSound.play();
            }
        }, 5000);

        this.myInfoService.enterpriseList().then(res => {
            if (res.data.success) {
                this.enterpriseList = res.data.data;
                if (this.$store.getters.currentSelectedSite) {
                    this.currentSelectedSiteId = this.$store.getters.currentSelectedSite.id;
                } else {
                    if (this.enterpriseList.length > 0 && this.enterpriseList[0].siteList.length > 0) {
                        let defaultEnterprise = this.enterpriseList[0];
                        this.$store.dispatch('changeSelectedEnterprise', defaultEnterprise);
                        let defaultSite = defaultEnterprise.siteList[0];
                        this.currentSelectedSiteId = defaultSite.id;
                        this.$store.dispatch('changeSelectedSite', defaultSite);
                        location.reload();
                    }
                }
                // 启动实时数据定时获取任务
                checkAndGetRTD();
            } else {
                // console.log('获取关联企业信息失败！')
            }
        });
        if (this.$store.getters.alarmNotice) {
            this.d_alarmNotice = this.$store.getters.alarmNotice;
        } else {
            this.d_alarmNotice = 'disable';
            this.$store.dispatch('changeAlarmNotice', 'disable');
        }

    },
    methods: {
        onChangeRightMenuActive() {
            this.$emit('rightmenu-active', false);
        },
        onRightMenuClick() {
            this.$emit('rightmenu-click');
        },
        changeColorScheme(scheme) {
            this.$emit('change-color-scheme', scheme);
        },
        changeAlarmNotice() {
            this.$store.dispatch('changeAlarmNotice', this.d_alarmNotice);
        },
        changeSelectedSite(enterprise, site) {
            this.$store.dispatch('changeSelectedEnterprise', enterprise);
            this.$store.dispatch('changeSelectedSite', site);
            location.reload();
        },
    },
};
</script>