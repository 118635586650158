import request from '../config/axios';
import store from '../store';

export default class SiteFactorDataService {
    newestRealtimeData = () => {
        const siteId = store.getters.currentSelectedSite.id;
        if (siteId === null || siteId === '') {
            console.log('siteId is NULL!');
            return [];
        }
        return request({
            url: '/basiceSite/newestRtdBySiteId/' + siteId, method: 'get',
        });
    };
    realtimeChartData = (dataType, startTs, endTs) => {
        const siteId = store.getters.currentSelectedSite.id;
        return request({
            url: '/v1/app/' + siteId + '/platformRecordChartV5/0/' + dataType + '/' + startTs + '/' + endTs,
            method: 'get',
        });
    };
}
