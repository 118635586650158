import store from '../store';

let timer;
let timerChart;

export function checkAndGetRTD() {
    clearRTDTimer();

    timer = setInterval(() => {
        // console.log(dayjs().format('YYYY年MM月DD日 HH:mm:ss'));
        store.dispatch('UpdateRTD');
    }, 5 * 1000);
    console.log('RTD定时器启动成功');

    timerChart = setInterval(() => {
        store.dispatch('UpdateRTDChart');
        store.dispatch('UpdateDataUpPct');
    }, 60 * 1000);
    console.log('Chart定时器启动成功');
}

export function clearRTDTimer() {
    // console.log('准备清除定时器');
    if (timer != null) {
        clearInterval(timer);
        timer = null;
        console.log('清除RTD定时器成功');
    }
    if (timerChart != null) {
        clearInterval(timerChart);
        timerChart = null;
        console.log('清除Chart定时器成功');
    }
}