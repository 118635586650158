const getters = {
    token: state => state.user.token,
    userInfo: state => state.user,
    alarmNotice: state => state.user.alarmNotice,
    currentSelectedSite: state => state.user.selectedSite,
    routerRoot: state => state.user.routerRoot,
    currentSelectedEnterprise: state => state.user.selectedEnterprise,
    rtdList: state => state.rtd.rtdList,
    rtdListChart: state => state.rtd.rtdListChart,
    rtdUpdateDateTime: state => state.rtd.rtdUpdateDateTime,
    alarmList: state => state.rtd.alarmList,
    last30DaysDataUpPct: state => state.rtd.last30DaysDataUpPct,
    countAnalyze: state => state.rtd.countAnalyze,
};
export default getters;
