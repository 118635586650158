<template>
    <div class='exception-body accessdenied'>
        <div class='exception-panel'>
            <h1>ACCESS</h1>
            <h3>denied</h3>
            <p>无权访问此页面，或会话过期.</p>
            <router-link to='/login'>
                <Button type='button' label='去登录页面'></Button>
            </router-link>
        </div>
        <div class='exception-footer'>
            <img :src="'layout/images/logo/logo.png'" class='exception-logo' />
            <img :src="'layout/images/appname-'+ color + '.png'" class='exception-appname' />
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        color() {
            if (this.$appState.colorScheme === 'light') return 'dark';
            return 'light';
        },
    },
};
</script>

<style scoped>

</style>
