<template>
    <div class='layout-breadcrumb-container'>
        <div class='layout-breadcrumb-left-items'>
            <a href='#' class='menu-button' @click='onMenuButtonClick($event)' v-if='isStatic()'>
                <i class='pi pi-bars'></i>
            </a>

            <Breadcrumb :model='items' class='layout-breadcrumb'></Breadcrumb>
        </div>
        <div class='layout-breadcrumb-right-items'>

            <div class='layout-rightmenu-button-desktop'>
                <p class='mr-8 mt-2'><strong>{{ enterpriseName }}</strong> <span
                    style='font-size: small'>{{ $store.getters.userInfo.userInfo.licenseExpireDate === null ? '（试用版）' : '（旗舰版）'
                    }}</span>
                </p>
            </div>

            <span class='layout-rightmenu-button-desktop'>
				<Button label='快捷设置' icon='pi pi-cog'
                        class='layout-rightmenu-button' @click='onRightMenuButtonClick()'></Button>
			</span>

            <span class='layout-rightmenu-button-mobile'>
				<Button icon='pi pi-bookmark'
                        class='layout-rightmenu-button' @click='onRightMenuButtonClick()'></Button>
			</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        menuMode: String,
        searchActive: {
            type: Boolean,
            default: false,
        },
        searchClick: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['menubutton-click', 'rightmenu-button-click', 'update:searchActive', 'update:searchClick'],
    data() {
        return {
            items: [],
            search: '',
            enterpriseName: '',
        };
    },
    watch: {
        $route() {
            this.watchRouter();
        },
    },
    created() {
        this.watchRouter();
    },
    mounted() {
        if (this.$store.getters.currentSelectedEnterprise) {
            this.enterpriseName = this.$store.getters.currentSelectedEnterprise.enterpriseName;
        }
    },
    methods: {
        watchRouter() {
            if (this.$router.currentRoute.value.meta.breadcrumb) {
                this.items = [];
                const bc = this.$router.currentRoute.value.meta.breadcrumb[0];
                for (let pro in bc) {
                    this.items.push({ label: bc[pro] });
                }
            }
        },
        onMenuButtonClick(event) {
            this.$emit('menubutton-click', event);
        },
        onRightMenuButtonClick() {
            this.$emit('rightmenu-button-click');
        },
        inputClick() {
            this.$emit('update:searchClick', true);
        },
        breadcrumbClick() {
            this.$emit('update:searchActive', true);
            this.$emit('update:searchClick', true);
        },
        isStatic() {
            return this.menuMode === 'static';
        },
    },
};
</script>

<style scoped>
::v-deep(.p-breadcrumb > ul > li.p-breadcrumb-chevron:first-of-type) {
    display: none;
}
</style>